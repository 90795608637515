import React, { useRef, useState, useEffect } from "react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const Skydrop = () => {

    var currentDay = 0;

    function milesToMeters( inMiles ) {
        return 1609.344 * inMiles;
    }

    function rotatePointAroundCenter(centerX, centerY, pointX, pointY, degrees) {
        // Convert degrees to radians
        const radians = degrees * (Math.PI / 180);

        // Translate point to origin (center becomes 0,0)
        const translatedX = pointX - centerX;
        const translatedY = pointY - centerY;

        // Apply the rotation
        const rotatedX = translatedX * Math.cos(radians) - translatedY * Math.sin(radians);
        const rotatedY = translatedX * Math.sin(radians) + translatedY * Math.cos(radians);

        // Translate the point back
        const finalX = rotatedX + centerX;
        const finalY = rotatedY + centerY;

        return { rlat: finalX, rlng: finalY };
    }



    function deepCopy( inObject ) {
        return JSON.parse( JSON.stringify( inObject ) );
    }


    function nextDay() {
        if( currentDay < 21 ) {
            currentDay++;
            setDay( currentDay );
        }
        return false;
    }

    function prevDay() {
        if( currentDay > 0 ) {
            currentDay--;
            setDay( currentDay );
        }
        return false;
    }

    useEffect(() => {

        var satLayer = L.tileLayer( 
                    'https://api.mapbox.com/styles/v1/jasonrohrer/cl978qf6v000e14lqfbg2tuzx/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiamFzb25yb2hyZXIiLCJhIjoiY20xZmR4MXoyMXN2ZTJqcTMwY3FrNGFhZCJ9.r-IBAwZl4qcIQvPsSh8tHw',
                    {  attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery &copy; <a href="https://stadiamaps.com/">Stadia</a> / <a href="https://www.mapbox.com/">Mapbox</a>',
                        maxZoom: 19
                    } 
                );

        var streetLayerA = L.tileLayer(
                    'https://api.mapbox.com/styles/v1/jasonrohrer/cl97ctsaj000816usr26u0ium/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiamFzb25yb2hyZXIiLCJhIjoiY20xZmR4MXoyMXN2ZTJqcTMwY3FrNGFhZCJ9.r-IBAwZl4qcIQvPsSh8tHw',
                    {  attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery &copy; <a href="https://stadiamaps.com/">Stadia</a> / <a href="https://www.mapbox.com/">Mapbox</a>',
                        maxZoom: 16
                    } 
                );
        var streetLayerB = L.tileLayer(
                    'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
                    {  attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery &copy; <a href="https://stadiamaps.com/">Stadia</a> / <a href="https://www.mapbox.com/">Mapbox</a>',
                            minZoom: 7,
                            maxZoom: 20
                    } 
                );



        var circleStyle = {
            color: 'red',
            fillColor: '#f03',
            // start at 0 until live circle is loaded
            opacity: 0.0,
            fillOpacity: 0.0,
            radius: milesToMeters( 250 )
        }


        // starting circle for contest
        var circleCenter = [42.486806800325, -72.665907935874];
        var circleBlinkStyle = deepCopy( circleStyle );

        var myMap = L.map( 'map', 
        {
        center: [42.486806800325, -72.665907935874],
        zoom: 6 });

        streetLayerA.addTo( myMap );
        streetLayerB.addTo( myMap );


        var circle = L.circle(
            circleCenter, 
            circleBlinkStyle  
        ).addTo( myMap );

        // this stuff is for testing/comparing
        // circle size changes (and drone visible area) day by day

        document.getElementById( "nextPrevControls" ).innerHTML = 
            '[<a href="#" id="prevDay">Prev Day</a>]' +
            '<div id="dayDisplay"></div>' +
            '[<a href="#" id="nextDay">Next Day</a>]';
        

        var circle2 = L.circle(
            circleCenter, 
            circleBlinkStyle  
        ).addTo( myMap );
        
        var visDiameters = [
            2,
            4,
            9,
            18,
            30,
            46,
            65,
            88,
            114,
            143,
            177,
            213,
            254,
            297,
            344,
            395,
            449,
            507,
            568,
            633,
            701,
            773 ];
        
        var circleDiameters = [
            499.999961,
            395.493610050074,
            312.664797484629,
            247.016995904715,
            194.986383382403,
            153.748374274796,
            121.064280955121,
            95.1597821869644,
            74.6286005547827,
            58.356159887766,
            45.4590782679469,
            35.2372118797396,
            27.1356463055715,
            20.7145718892333,
            15.6254077909242,
            11.5918785806697,
            8.39501607521775,
            5.86127221319684,
            3.85309765223895,
            2.26147462840977,
            0.99999870931694,
            0.00018815705273 ];

            
            
        
        var sample_data = [
            [ [ 41.461227605394, -73.992551089757 ], 395.49361005007 ], 
            [ [ 41.694343319531, -73.28195888392 ], 312.66479748463 ], 
            [ [ 42.039040449458, -72.924521369962 ], 247.01699590472 ],
            [ [ 42.389134038211, -72.753888967289 ], 194.9863833824 ], 
            [ [ 42.501904503654, -72.400838505704 ], 153.7483742748 ], 
            [ [ 42.69868659631, -72.249438609981 ], 121.06428095512 ], 
            [ [ 42.820962859065, -72.074276927289 ], 95.159782186964 ], 
            [ [ 42.840967769072, -71.879573903207 ], 74.628600554783 ], 
            [ [ 42.930682189266, -71.776722039872 ], 58.356159887766 ],
            [ [ 42.98993968477, -71.687838750699 ], 45.459078267947 ],
            [ [ 43.011027342069, -71.59108409993 ], 35.23721187974 ],
            [ [ 43.035627973778, -71.534635365214 ], 27.135646305571 ],
            [ [ 43.061803017915, -71.499608790696 ], 20.714571889233 ],
            [ [ 43.072892443084, -71.458847570372 ], 15.625407790924 ],
            [ [ 43.089661562269, -71.432349554837 ], 11.59187858067 ],
            [ [ 43.10705447711, -71.415609711407 ], 8.3950160752178 ],
            [ [ 43.117831317005, -71.397425843829 ], 5.8612722131968 ],
            [ [ 43.129087906831, -71.388551236889 ], 3.8530976522389 ],
            [ [ 43.135453200186, -71.377453170651 ], 2.2614746284098 ],
            [ [ 43.14101083832, -71.36925891202 ], 0.99999870931694 ],
            [ [ 43.1458846, -71.3634452 ], 0.00018815705273 ] ];

            var known_data = [
            [ [41.292623061735, -75.375917008862], 395.49361005007 ],
            [ [41.729968234873, -74.836408543068], 312.66479748463 ],
            [ [42.029096231532, -74.382994943007], 247.01699590472 ],
            [ [42.272627204498, -74.042666556074], 194.9863833824 ],
            [ [42.275847303204, -73.671495870984], 153.7483742748 ],
            [ [42.288834730476, -73.359477386313], 121.06428095512 ],
            [ [42.320237635702, -73.110613115148], 95.159782186964 ],
            [ [42.404491682247, -72.975152286208], 74.628600554783 ],
            [ [42.43178423219, -72.829357748827 ], 58.356159887766],
            [ [42.437035601116, -72.723873641505], 45.459078267947],
            [ [ 42.486806800325, -72.665907935874 ], 35.23721187974 ],
            [ [ 42.525542962371, -72.611355509733 ], 27.135646305571 ],
            [ [ 42.535279106257, -72.555065867998 ], 20.714571889233 ],
            [ [ 42.543548158541, -72.506721679261 ], 15.625407790924 ],
        ];



        var circleDiamPosition = known_data;

        // show nested circles for visual reference

        
        var circletestNestStyleFlipped = {
            color: 'orange',
            opacity: 0.75,
            fillColor: '#f03',
            fillOpacity: 0.0,
            radius: milesToMeters( 250 )
        }
        for( let i = 0; i < 21; i++ ) {


            var circleNestFlipped = L.circle(
                circleCenter, 
                circletestNestStyleFlipped  
            ).addTo( myMap );

            circleNestFlipped.setRadius( milesToMeters( 
                sample_data[i][1] / 2 ) );

            var prevlatlng = sample_data[0][0]
            var {prevlat, prevlng} = {prevlat: prevlatlng[0], prevlng:prevlatlng[1]}
            
            var latlng = sample_data[i][0];
            var {lat, lng} = {lat: latlng[0], lng:latlng[1]}

            var dynamicRotate = [0, -30, -20, -12, -8, -2, -1, -2, -2, -1, -2, -2, -2, -2, -2, -2, -2 , -2, -2, -2, -2]
            var {rlat, rlng} = rotatePointAroundCenter(prevlat, prevlng, lat, lng, 10+((dynamicRotate[i])?dynamicRotate[i]:0))

            rlng -= 1.38-(i*(.0162-i/2000))
            rlat -= .17

            circleNestFlipped.setLatLng( [rlat, rlng] );

            }
        var circleNestStyle = {
            color: 'blue',
            opacity: 0.75,
            fillColor: '#f03',
            fillOpacity: 0.0,
            radius: milesToMeters( 250 )
        }
        for( let i = 0; i < circleDiamPosition.length; i++ ) {
            var circleNest = L.circle(
                circleCenter, 
                circleNestStyle  
            ).addTo( myMap );

            circleNest.setRadius( milesToMeters( 
                circleDiamPosition[i][1] / 2 ) );
                //5 ) );
            circleNest.setLatLng( circleDiamPosition[i][0] );
            }

        for( let i = 0; i < circleDiamPosition.length; i++ ) {
            //L.marker( circleDiamPosition[i][0] ).addTo( myMap );
        }

        setDay( currentDay );




        document.getElementById('nextDay').onclick = nextDay;
        document.getElementById('prevDay').onclick = prevDay;
        function setDay( inDay ) {

            circle2.setRadius( 
                milesToMeters( 0.5 * visDiameters[inDay] / 5280 ) );
    
            circle.setRadius( milesToMeters( 
                circleDiamPosition[inDay][1] / 2 ) );
    
            circle.setLatLng( circleDiamPosition[inDay][0] );
            
            console.log( circleDiamPosition[inDay] );
            
            document.getElementById("dayDisplay").innerHTML = 
                currentDay + ": circleD: " + circleDiameters[inDay] +
                " miles, visD: " + visDiameters[inDay] + " feet"; 
        }
        
    });


    return (
        <div >
            <div class="row">
                <div class="col-sm" style={{padding:20}}>
                    <h1>Skydrop Analysis</h1>
                    <h2>The answer was in the sample data the whole time.</h2>
                    <p>
                        <h3>1. Site analysis:</h3>
                        No UI framework used, sourceforge, old school, tables, direct DOM manipulation, links to stack overflow, fetch and XMLHttpRequest both used.
                        <br/>API is php, no framework, custom
                        <br/>endpoint for circle: <a href="https://projectskydrop.com/getCurrentCircle.php?sub_days=x">https://projectskydrop.com/getCurrentCircle.php?sub_days=x</a>
                        <br/>endpoint for clues: <a href="https://projectskydrop.com/server/server.php?action=old_clue_image&secret_words=gravity+forest+lime+flesh&file_name=0013.jpg">https://projectskydrop.com/server/server.php?action=old_clue_image&secret_words=gravity+forest+lime+flesh&file_name=0013.jpg</a>
                        <br/>Hit clues endpoint at 9am and update file count to get new image, you dont have to wait to have the clue sent to you, not secure at all
                        <br/>Lots of leftover test code with test circles, could define human behavior for repeatability
                        <hr/>Final Analysis: Very possible to be able to exploit
                        <h3>2. Person analysis:</h3>
                        Jason very smart, went to Cornell, accepted to PHD program UC Santa Cruz
                        <br/>Extreme lifestyle living on minimal budget
                        <br/>No phone
                        <h3>3. Finding the patterns</h3>
                        Human behavior generally repeats itself, by using the test data, its very possible the real data follows the same pattern.
                        <br/>Test circles move in a mostly linear pattern north east, early set of real coordinates do too
                        <br/>Translate the test circles starting with the 2nd one to the 2nd real circle and see how close the rest match
                        <br/>Figure out they are close, but are slightly off just by a little rotation and scaling, but are pretty close and follow the repeatable predictable behavior
                        <br/>Map out all sample coordinates to Erving/Wendell
                    </p>
                </div>
                <div class="col-sm" style={{padding:40}}>
                    <div style={{width:640,height:360}} id="map"></div>
                    <div id="nextPrevControls"></div>
                    <SyntaxHighlighter lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}} wrapLines={true} language="javascript"  style={dark}>
                        var prevlatlng = sample_data[0][0];
                        var prevlat, prevlng = prevlat: prevlatlng[0], prevlng:prevlatlng[1]; //the first (seconds circle we pivot around);
                    </SyntaxHighlighter>
                    <SyntaxHighlighter lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}} wrapLines={true} language="javascript"  style={dark}>

                        var latlng = sample_data[i][0];
                        var lat, lng = lat: latlng[0], lng:latlng[1]; // current circle in the array
                    </SyntaxHighlighter>
                    <SyntaxHighlighter lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}} wrapLines={true} language="javascript"  style={dark}>

                        var dynamicRotate = [0, -30, -20, -12, -8, -2, -1, -2, -2, -1, -2, -2, -2, -2, -2, -2, -2 , -2, -2, -2, -2];
                        var rlat, rlng = rotatePointAroundCenter(prevlat, prevlng, lat, lng, 10+((dynamicRotate[i])?dynamicRotate[i]:0));

                        rlng -= 1.38-(i*(.0162-i/2000));
                        rlat -= .17;

                        circleNestFlipped.setLatLng( [rlat, rlng] ); //add sample data circle to map
                    
                    </SyntaxHighlighter>
                </div>
            </div>
        </div>
    )
};

export default Skydrop;