import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { FaThumbsUp } from 'react-icons/fa';

import json from "data.json"
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";

var teams = Object.keys(json).map((key) => [key, json[key]]);

console.log(teams);
/*
<!--
      <div className="logo-img" style={{display:"inline-block"}}>
        <img style={{width:"50px", marginBottom:"20px"}} src={require("assets/img/icon.png")} alt="..." />
      </div>
      <h1 style={{display:"inline-block"}}>&nbsp;Threesday</h1>-->
      */


function TableList() {
  return (
    <>
      
      <p>Data last run: 3/12/2024 12:00 AM ET, Last 100 Games</p>
      <Container fluid style={{padding: "0"}}>
        <Row>
          <Col md="12">
          <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="home" title="1st 3 Game">
            {Object.values(json).map((team) => (

              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">{team.name}</Card.Title>
                </Card.Header>
                    <Card.Body className="table-half-width table-responsive px-0">
                      <Table className="table-hover table-striped" style={{fontSize:"xx-small", overflowX: "hidden"}}>
                        <thead>
                          <tr>
                            <th className="border-0">PLYR</th>
                            <th className="border-0">MD</th>
                            <th className="border-0">MD AVG Sec</th>
                            <th className="border-0">ATT</th>
                            <th className="border-0">ATT AVG Sec</th>
                            <th className="border-0">TOT AVG Sec</th>
                            <th className="border-0">TOT MD AVG Sec</th>
                            <th className="border-0">TOT ATT</th>
                            <th className="border-0">TOT MD</th>
                            <th className="border-0">MD %</th>
                          </tr>
                        </thead>
                        <tbody style={{overflowX: "hidden"}}>
                          {Object.values(team.players).map((player, id) => (
                            <tr key={id}>
                                <td>{player.name}</td>
                                <td><b>{Object.values(player.threes.firstThree).length}</b></td>
                                <td>
                                  <b>{(Object.values(player.threes.firstThree).length>0)?(Object.values(player.threes.firstThree).reduce((a, b) => a + b) / Object.values(player.threes.firstThree).length).toFixed(2):"-"}</b>
                                </td>
                                <td>
                                  {Object.values(player.threes.firstThreeAttempt).length}
                                </td>
                                <td>
                                  {(Object.values(player.threes.firstThreeAttempt).length>0)?(Object.values(player.threes.firstThreeAttempt).reduce((a, b) => a + b) / Object.values(player.threes.firstThreeAttempt).length).toFixed(2):"-"}
                                </td>
                              
                                <td>
                                  {(Object.values(player.threes.attempts).length>0)?(Object.values(player.threes.attempts).reduce((a, b) => a + b) / Object.values(player.threes.attempts).length).toFixed(2):"-"}
                                </td>
                                <td>
                                  {(Object.values(player.threes.made).length>0)?(Object.values(player.threes.made).reduce((a, b) => a + b) / Object.values(player.threes.made).length).toFixed(2):"-"}
                                </td>
                                <td>{player.totalThreeAttempts}</td>
                                <td>{player.totalThreeMade}</td>
                                
                                <td>{(player.totalThreeMade/player.totalThreeAttempts*100).toFixed(2)}%</td>
                            </tr>
                            ))}

                          <tr>
                          </tr>
                        
                        </tbody>
                      </Table>
                    </Card.Body>              
              </Card>
              ))}
            </Tab>
            <Tab eventKey="profile" title="1st 3 Team">
            {Object.values(json).map((team) => (

              <Card className="strpied-tabled-with-hover">
                <Card.Header>
                  <Card.Title as="h4">{team.name}</Card.Title>
                </Card.Header>
                    <Card.Body className="table-half-width table-responsive px-0">
                      <Table className="table-hover table-striped" style={{fontSize:"xx-small", overflowX: "hidden"}}>
                        <thead>
                          <tr>
                            <th className="border-0">PLYR</th>
                            <th className="border-0">MD</th>
                            <th className="border-0">MD AVG Sec</th>
                            <th className="border-0">ATT</th>
                            <th className="border-0">ATT AVG Sec</th>
                            <th className="border-0">TOT AVG Sec</th>
                            <th className="border-0">TOT MD AVG Sec</th>
                            <th className="border-0">TOT ATT</th>
                            <th className="border-0">TOT MD</th>
                            <th className="border-0">MD %</th>
                          </tr>
                        </thead>
                        <tbody style={{overflowX: "hidden"}}>
                          {Object.values(team.players).map((player, id) => (
                            <tr key={id}>
                                <td>
                                  {player.name}
                                </td>
                                <td><b>{Object.values(player.threes.firstTeamThree).length}</b></td>
                                <td>
                                  <b>{(Object.values(player.threes.firstTeamThree).length>0)?(Object.values(player.threes.firstTeamThree).reduce((a, b) => a + b) / Object.values(player.threes.firstTeamThree).length).toFixed(2):"-"}</b>
                                </td>
                                <td>
                                  {Object.values(player.threes.firstThreeTeamAttempt).length}
                                </td>
                                <td>
                                  {(Object.values(player.threes.firstThreeTeamAttempt).length>0)?(Object.values(player.threes.firstThreeTeamAttempt).reduce((a, b) => a + b) / Object.values(player.threes.firstThreeTeamAttempt).length).toFixed(2):"-"}
                                </td>
                              
                                <td>
                                  {(Object.values(player.threes.attempts).length>0)?(Object.values(player.threes.attempts).reduce((a, b) => a + b) / Object.values(player.threes.attempts).length).toFixed(2):"-"}
                                </td>
                                <td>
                                  {(Object.values(player.threes.made).length>0)?(Object.values(player.threes.made).reduce((a, b) => a + b) / Object.values(player.threes.made).length).toFixed(2):"-"}
                                </td>
                                <td>{player.totalThreeAttempts}</td>
                                <td>{player.totalThreeMade}</td>
                                
                                <td>{(player.totalThreeMade/player.totalThreeAttempts*100).toFixed(2)}%</td>
                            </tr>
                            ))}

                          <tr>
                          </tr>
                        
                        </tbody>
                      </Table>
                    </Card.Body>              
              </Card>
              ))}
            </Tab>
            
            <Tab eventKey="contact" title="AI Pick" disabled>
              Tab content for Contact
            </Tab>
          </Tabs>
            
          </Col>
          
        </Row>
      </Container>
    </>
  );
}

export default TableList;
