import React, { useRef, useState, useEffect } from "react";
import "assets/css/calendar.css";

const months = [
  "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

const NbaCalendarView = () => {
  const dateRefs = useRef({}); // Reference for each date row
  const [gamesByDate, setGamesByDate] = useState({});
  const [activeMonth, setActiveMonth] = useState(null);
  const [expandedMonths, setExpandedMonths] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [allTeams, setAllTeams] = useState([]); // State to store all teams
  const [selectedTeam, setSelectedTeam] = useState(null); // New state for the selected team


  // Helper function to get the month from a date string in the format "YYYY-MM-DD"
  const getMonthFromDate = (date) => {
    const [year, month] = date.split("-");
    return `${months[parseInt(month) - 1]} ${year}`;
  };

  // Function to handle scrolling to the correct date row when a date is clicked
  const handleDateClick = (date) => {
    if (dateRefs.current[date]) {
      dateRefs.current[date].scrollIntoView({ behavior: "smooth" });
    }
  };

  // Handle click on month to expand/collapse its dates
  const handleMonthClick = (month) => {
    setExpandedMonths((prev) => ({
      ...prev,
      [month]: !prev[month] // Toggle the expansion state of the clicked month
    }));
  };

    // Function to filter games based on the selected team
    const filterGamesByTeam = (team) => {
        setSelectedTeam(team === selectedTeam ? null : team); // Toggle the team filter on/off
      };

  useEffect(() => {
    // Fetch data from the NBA schedule JSON
    const fetchGames = async () => {
      try {
        const response = await fetch(
          "/schedule.json"
        );
        const data = await response.json();
        const gameData = data.leagueSchedule.gameDates;

        // Transform the data to a structure of { date: [games] }
        const gamesByDate = {};
        const allTeamsSet = new Set();

        gameData.forEach((gameDate) => {
          const date = new Date(gameDate.gameDate).getMonth()+1+"/"+new Date(gameDate.gameDate).getDate();
          //new Date(gameDate.gameDate).toDateString()//gameDate.gameDate;
          
          gamesByDate[date] = gameDate.games.map((game) => ({
                gameId: game.gameId,
                homeTeam: game.homeTeam.teamTricode,
                awayTeam: game.awayTeam.teamTricode,
                month: game.monthNum,
                homeScore: game.homeTeam.score || "0",
                awayScore: game.awayTeam.score || "0",
            }));

          gameDate.games.map((game) => {
            allTeamsSet.add(game.homeTeam.teamTricode);
            allTeamsSet.add(game.awayTeam.teamTricode);
            });
            
        });
        setAllTeams(Array.from(allTeamsSet)); // Store all unique teams

        setGamesByDate(gamesByDate);
        setActiveMonth(getMonthFromDate(Object.keys(gamesByDate)[0]));
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch game data");
        setLoading(false);
      }
    };

    fetchGames();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const date = entry.target.dataset.date;
            const month = getMonthFromDate(date);
            setActiveMonth(month);
          }
        });
      },
      { root: null, threshold: 0.5 }
    );

    // Observe each date row
    Object.keys(gamesByDate).forEach((date) => {
      if (dateRefs.current[date]) {
        observer.observe(dateRefs.current[date]);
      }
    });

    return () => {
      // Cleanup observer on unmount
      Object.keys(gamesByDate).forEach((date) => {
        if (dateRefs.current[date]) {
          observer.unobserve(dateRefs.current[date]);
        }
      });
    };
  }, [gamesByDate]);

  if (loading) return <div>Loading games...</div>;
  if (error) return <div>{error}</div>;

  // Group dates by month
  const gamesByMonth = {};
  Object.keys(gamesByDate).forEach((date) => {
    const month = months[gamesByDate[date][0].month-1];
    if (!gamesByMonth[month]) gamesByMonth[month] = [];
    gamesByMonth[month].push(date);
  });

  return (
    <div className="calendar-container">
         {/* Sticky div for team selection */}
      <div className="team-filter">
        <div className="team-list">
          {allTeams.map((team) => (
            <div
              key={team}
              className={`team-item ${selectedTeam === team ? "active" : ""}`}
              onClick={() => filterGamesByTeam(team)}
            >
              {team}
            </div>
          ))}
        </div>
      </div>
      <div className="calendar-view">
        {Object.keys(gamesByDate).map((date) => (
            <div>
            {gamesByDate[date].filter(
                (game) =>
                  !selectedTeam ||
                  game.homeTeam === selectedTeam ||
                  game.awayTeam === selectedTeam
              ).length>0?
          <div
            className="calendar-row"
            key={date}
            ref={(el) => (dateRefs.current[date] = el)}
            data-date={date}
          >
            <div className="date-row">{date}</div>
            <div className="games-row">
            
              {gamesByDate[date].filter(
                    (game) =>
                      !selectedTeam ||
                      game.homeTeam === selectedTeam ||
                      game.awayTeam === selectedTeam
                  ).map((game) => (
                <div className="game-card" key={game.gameId}>
                  <div className="teams">
                    {game.awayTeam} @ {game.homeTeam}
                  </div>
                  <div className="score">
                    Score: {game.awayScore} - {game.homeScore}
                  </div>
                </div>
              ))}
            </div>
          </div>:<div></div>}
          </div>
        ))}
      </div>

      {/* Date slider on the right */}
      <div className="date-slider">
        <p className="calendar-name">Calendar</p>
        {Object.keys(gamesByMonth).map((month) => (
          <div key={month} className="month-section">
            <div
              className={`month-item ${activeMonth === month ? "active" : ""}`}
              onClick={() => handleMonthClick(month)}
            >
              {month}
            </div>
            {expandedMonths[month] && (
              <div className="dates-list">
                {gamesByMonth[month].map((date) => (
                  <div
                    className={`date-item ${activeMonth === month ? "active" : ""}`}
                    key={date}
                    onClick={() => handleDateClick(date)}
                  >
                    {date}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NbaCalendarView;
