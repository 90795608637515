import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function Home() {
  return (
    <>
      <Container fluid style={{minHeight:"100vh"}}>
        <Row>
          <Col className="ml-auto mr-auto" xs="12">
            <Card>
              <div className="header text-center">
              <a href="https://apps.apple.com/us/app/threesday/id6479314145?itscg=30200&amp;itsct=apps_box_appicon" style={{width: "170px", height: "170px", borderRadius: "22%", overflow: "hidden", display: "inline-block", verticalAlign: "middle"}}>
                <img src="https://is1-ssl.mzstatic.com/image/thumb/Purple211/v4/aa/50/3e/aa503e6a-b623-ab5a-aeab-e15ce3974377/AppIcon-0-0-1x_U007emarketing-0-10-0-85-220.png/540x540bb.jpg" alt="Threesday" style={{width: "170px", height: "170px", borderRadius: "22%", overflow: "hidden", display: "inline-block", verticalAlign: "middle"}} />
                </a>
                <h1 className="title" style={{letterSpacing: "0.05em"}}>Threesday</h1>
                <p className="category">
                  Card Counting for NBA bets
                </p>
                <br></br>
              </div>
              <div style={{textAlign:"center"}}>
                <a href="https://apps.apple.com/us/app/threesday/id6479314145?itsct=apps_box_badge&amp;itscg=30200" style={{margin:"15px", display: "inline-block", overflow: "hidden", borderRadius: "13px", width: "250px", height: "83px"}}>
                  <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/white/en-us?size=250x83&amp;releaseDate=1711497600" alt="Download on the App Store" style={{borderRadius: "13px", width: "250px", height: "83px"}} />
                </a>
                <br />
                <img style={{width:"200px"}} src="https://tools-qr-production.s3.amazonaws.com/output/apple-toolbox/6f7912593fa6243a55648686281717fd/4bf60621d4d08fefbad0810cf973a7b0.png"></img>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Home;
